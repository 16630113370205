<template>
  <div>
      <h2 class="flix-html-h2" v-html="$t('message.imprint')"/>
    <div v-if="imprint" v-html="imprint">
    </div>
    <div v-else>
      <router-link class="flix-html-a" :to="{name: 'frontend', params: { page: 'imprint' }}">{{ $t('message.goTo', {name: $t('message.imprint') }) }}</router-link>
    </div>
    <h2 class="flix-html-h2" v-html="$t('message.privacy')"/>
    <div v-if="privacy" v-html="privacy">
    </div>
    <div class="flix-form-group" v-else>
      <router-link class="flix-html-a" :to="{name: 'frontend', params: { page: 'privacy' }}">{{ $t('message.goTo', {name: $t('message.privacy')}) }}</router-link>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object
  },
  data () {
    return {
      imprint: false,
      privacy: false
    }
  },
  methods: {
    getImprint () {
      this.$flix_post({
        url: 'user/get_imprint',
        data: { user: this.$route.params.user },
        callback: function (ret) { this.imprint = ret.data[1].imprint; this.privacy = ret.data[1].privacy }.bind(this)
      })
    }
  },
  mounted () {
    this.getImprint()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
